<template>
 <v-container class="tips-bg-glass-effect pa-0">
   <v-row v-if="statusLoad" style="margin-left: 5px;">
     <v-col cols="12">
       <backArrow />
     </v-col>
   </v-row>
   <v-layout v-if="statusLoad" align-center justify-center>
     <genericCarousel v-model="currentCarouselItem" :data="tipsChildren">
       <v-row
         v-for="(tip, index) in tipsChildren"
         :key="tip.id"
         v-if="index === currentCarouselItem"
         style="left: 50%; margin-left: -160px; position: absolute; top: 70px"
         align="center"
         justify="center"
       >
         <v-col cols="12">
           <v-img
             v-if="!tip.videoUrl"
             :src="`data:image;base64,${tip.media.contents}`"
             style="height: 350px; width: 300px; objectFit: 'cover';"
           />

           <iframe
             v-if="tip.videoUrl"
             style="height: 350px; width: 300px;"
             :src="tip.videoUrl"
             allowfullscreen
           />
         </v-col>

         <v-col style="transform: translate(-5%); margin-top: 20px" cols="12">
           <p style="margin: 0;" class="main-title text-center">
             {{ tip.internal_description ? tip.internal_description : ''  }}
           </p>

           <p class="main-text text-center pt-3">{{ tip.contents ? tip.contents : '' }}</p>
         </v-col>
       </v-row>
     </genericCarousel>
   </v-layout>
 </v-container>
</template>

<script>
import genericCarousel from '../../components/generic-carousel';
import backArrow from '../../components/covid/back-arrow';
import { mapMutations } from "vuex";
import SocialNetworkService from "@/services/socialnetwork/SocialNetworkService";

export default {
  name: "TipDetail",
  data() {
    return {
      statusLoad: false,
      tipsChildren: [],
      currentCarouselItem: 0,
    }
  },
  components: {
    genericCarousel,
    backArrow,
  },
  methods: {
    ...mapMutations(["loading", "showmsg"]),
    orderTips() {
      this.tipsChildren.sort((a, b) => (a.feed_order > b.feed_order) ? 1 : -1)
    },
    async getMediaTips() {
      for(let i=0; i < this.tipsChildren.length; i++){
        await this._socialNetworkService.FindMediaTips(this.tipsChildren[i].id)
            .then((response) => {
              this.tipsChildren[i].media = {contents: response.data[0].contents, type: response.data[0].type}
            }).catch(() => {
              this.showmsg({
                text: "Não foi possível listar as medias do .",
                type: "error"
              });
            })
      }
    }
  },
  async mounted() {
    this.loading(true);
    this.tipsChildren = this.$route.params.tipsChildren
    this._socialNetworkService = new SocialNetworkService();
    await this.orderTips();
    await this.getMediaTips();
    this.loading(false);
    this.statusLoad = true
  }
}
</script>

<style scoped>
.tips-bg-glass-effect {
  background: rgba(255, 255, 255, .5);
  mix-blend-mode: normal;
  backdrop-filter: blur(53px);
  padding: 10px;
  height: 100vh;
}
</style>
