<template>
  <v-flex class="pa-0">
    <v-row :style="carouselLength" align="center">
      <div :style="cssVars" class="slide">
        <slot/>
      </div>
    </v-row>

    <v-btn
      v-if="showArrows"
      class="prev"
      icon
      :color="color"
      @click="prevSlide"
      :disabled="currentSlide === 0"
    >
      <v-icon>
        fas fa-angle-left
      </v-icon>
    </v-btn>

    <v-btn
      v-if="showArrows"
      class="next"
      icon
      :color="color"
      @click="nextSlide"
      :disabled="currentSlide === data.length - 1"
    >
      <v-icon>
        fas fa-angle-right
      </v-icon>
    </v-btn>

    <v-row align="end" style="height: 490px">
      <v-col style="z-index: 1000;" class="text-center" cols="12">
        <span
          :style="cssVars"
          v-for="(item, index) in data"
          :key="index"
          :class="isControlActive(index) ? 'active slide-left' : 'desactive slide-left'"
          @click="goToSlide(index)"
        ></span>
      </v-col>
    </v-row>
  </v-flex>
</template>

<script>
import { eu_protegido_theme } from '../../plugins/vuetify';
export default {
  name: "GenericCarousel",
  props: {
    color: {
      type: String,
      default: 'tertiary',
    },
    theme: {
      default: 'light',
      type: String
    },
    value: {
      required: true,
      type: Number
    },
    data: {
      type: Array,
      default: v => []
    },
    showArrows: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      itemsTotal: null,
      currentSlide: 0,
      touch: {
        startX: 0,
        endX: 0
      }
    }
  },
  watch: {
    value() {
      this.currentSlide = this.value;
    },
  },
  methods: {
    goToSlide(slideIndex) {
      this.$emit('input', slideIndex)
    },
    isControlActive(controlIndex) {
      return controlIndex === this.currentSlide;
    },
    nextSlide() {
      let slide = this.currentSlide + 1;
      this.$emit('input', slide);
    },
    prevSlide() {
      let slide = this.currentSlide - 1;
      this.$emit('input', slide);
    },
    touchstart(event) {
      this.touch.startX = event.touches[0].clientX;
      this.touch.endX = 0;
    },
    touchmove(event) {
      this.touch.endX = event.touches[0].clientX;
    },
    touchend() {
      if(this.touch.endX < this.touch.startX)
        if (this.currentSlide < this.itemsTotal - 1) {
          this.nextSlide();
        }

      if(this.touch.endX > this.touch.startX)
        if (this.currentSlide > 0)
          this.prevSlide();
    }
  },
  computed: {
    cssVars() {
      return {
        '--active-color': eu_protegido_theme[this.color],
        '--desactive-color': this.theme === 'light' ? '#DBD9DE' : '#F1F1F5FF',
      }
    },
    carouselLength() {
      return { width: this.itemsTotal * 100 + '%' };
    },
  },
  mounted() {
    setTimeout(() => {
      this.$el.addEventListener('touchstart', event => this.touchstart(event));
      this.$el.addEventListener('touchmove', event => this.touchmove(event));
      this.$el.addEventListener('touchend', () => this.touchend());
    }, 0)

    this.itemsTotal = this.data.length;
  },
}
</script>

<style scoped>
 .desactive {
   width: 8px;
   height: 8px;
   border-radius: 50%;
   background: var(--desactive-color);
   display: inline-block;
   margin: 2%;
   cursor: pointer;
   transition: all .5s ease;
 }
 .active {
   width: 30px;
   height: 8px;
   border-radius: 22%;
   background: var(--active-color);
   display: inline-block;
   margin: 2%;
   cursor: pointer;
   transition: all .5s ease;
 }

 .prev, .next {
   cursor: pointer;
   top: 50%;
   width: 35px;
   margin-top: -22px;
   padding: 16px;
   color: white;
   font-weight: bold;
   font-size: 18px;
   transition: 0.6s ease;
   user-select: none;
   border-radius: 50%;
 }

 .next {
   right: 2%;
 }

 .slide > * {
   transition: all .5s ease;
 }


</style>